import "./main.pcss";
import { init } from "@cima/prism-utils";
import secondarySubmitButton from "./components/submitButton/secondarySubmitButton";
import trimOnChange from "./components/input/trimOnChange";
import loadingOnClick from "./components/button/loadingOnClick";
import lineItem from "./components/line-item";
import modalTrigger from "./components/modal-trigger";
import webauthnSupport from "./components/webauthn/support";

init.initCommon();
secondarySubmitButton();
trimOnChange();
loadingOnClick();
lineItem();
modalTrigger();
webauthnSupport();
